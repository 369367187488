import { Component } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

declare let $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [DeviceDetectorService],
})
export class AppComponent {
  myApps: MyApp[] = [];
  myApps2: MyApp[] = [];
  myApps3: MyApp[] = [];

  constructor(private device: DeviceDetectorService) {
    this.myApps.push(
      new MyApp(
        "懸賞！脳トレパズル",
        'パズルゲームやミニゲームを解いて脳トレしながら懸賞にも応募できる無料アプリ。ランキング機能やフレンド機能など、様々な機能が盛りだくさん。ユーザー数も日々増え続けている、BuildManの代表作アプリ。【 <a href="https://itunes.apple.com/jp/app/id1135665138?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=xyz.buildman.prizegame" target="_blank">Android</a> 】',
        "assets/images/icon_prize.png",
        "https://itunes.apple.com/jp/app/id1135665138?mt=8",
        "https://play.google.com/store/apps/details?id=xyz.buildman.prizegame"
      )
    );

    this.myApps.push(
      new MyApp(
        "S, ボールを落とすだけの物理演算ゲーム",
        'ワンタップでボールを落として、迫り来る赤いボールを落とすゲーム。シンプルながらも、クリアするためにはボールを落とす位置とタイミングが重要。オリジナルステージを作って公開することも可能です。【 <a href="https://itunes.apple.com/jp/app/id1002381557?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=xyz.buildman.s" target="_blank">Android</a> 】',
        "assets/images/icon_s.png",
        "https://itunes.apple.com/jp/app/id1002381557?mt=8",
        "https://play.google.com/store/apps/details?id=xyz.buildman.s"
      )
    );

    this.myApps.push(
      new MyApp(
        "FLAT-avoid-",
        '雑誌にも掲載されたクールでシンプルなゲーム。落ちてくる石に押されてステージから落下したらゲームオーバー。アイテムを獲得するかどうか、どのルートを進むかの判断によって、最終的なスコアが大きく違ってきます。【 <a href="https://itunes.apple.com/jp/app/id941553035?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=xyz.buildman.flatoneavoid" target="_blank">Android</a> 】',
        "assets/images/icon_avoid.png",
        "https://itunes.apple.com/jp/app/id941553035?mt=8",
        "https://play.google.com/store/apps/details?id=xyz.buildman.flatoneavoid"
      )
    );

    this.myApps.push(
      new MyApp(
        "FLAT-galaxy-",
        'ロケットで宇宙空間を移動しながら隕石に当たらないように避けるゲーム。ゲーム中に獲得できるネジを使って新しいロケットを獲得したり、長く遊び続けていると太陽系の外まで旅ができるなどやり込み要素が満載。【 <a href="https://itunes.apple.com/jp/app/id973746393?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=xyz.buildman.flatthreegalaxy" target="_blank">Android</a> 】',
        "assets/images/icon_galaxy.png",
        "https://itunes.apple.com/jp/app/id973746393?mt=8",
        "https://play.google.com/store/apps/details?id=xyz.buildman.flatthreegalaxy"
      )
    );

    this.myApps.push(
      new MyApp(
        "FLAT-mirror-",
        '現実の世界と鏡の世界の両方で、落ちてくるナイフを避け続けるゲーム。両方の世界でナイフの軌道を見極めながら上手にキャラを誘導するテクニックが必要。アイテムを獲得して新しいキャラクターを開放すればより一層楽しめます。【 <a href="https://play.google.com/store/apps/details?id=xyz.buildman.flatfourmirror" target="_blank">Android</a> 】',
        "assets/images/icon_mirror.png",
        "https://play.google.com/store/apps/details?id=xyz.buildman.flatfourmirror",
        "https://play.google.com/store/apps/details?id=xyz.buildman.flatfourmirror"
      )
    );

    this.myApps.push(
      new MyApp(
        "AREA12",
        'テトリスのブロックのようなものを用いた頭脳系陣取りパズルゲーム。オンラインで世界中のプレイヤーと対戦することができ、一定期間における対戦成績上位者にはギフト券がプレゼントされることもあります。【 <a href="https://itunes.apple.com/jp/app/id1444770521?mt=8" target="_blank">iOS</a> 】',
        "assets/images/icon_area12.png",
        "https://itunes.apple.com/jp/app/id1444770521?mt=8",
        "https://itunes.apple.com/jp/app/id1444770521?mt=8"
      )
    );

    this.myApps.push(
      new MyApp(
        "MyLyrics",
        'シンプルな歌詞表示アプリ。曲の進行度に合わせて表示された歌詞が自動でスクロールしていきます。iTunesでカスタム登録した歌詞が表示されるので、事前にパソコンで歌詞の登録をしておきましょう。【 <a href="https://itunes.apple.com/jp/app/id1264911434?mt=8" target="_blank">iOS</a> 】',
        "assets/images/icon_mylyrics.png",
        "https://itunes.apple.com/jp/app/id1264911434?mt=8",
        "https://itunes.apple.com/jp/app/id1264911434?mt=8"
      )
    );

    this.myApps.push(
      new MyApp(
        "タッチ・ザ・メモリー",
        '最初に表示された数字の位置を記憶して、1から順番に押していくゲーム。3×3の簡単なステージだけでなく、記憶するだけでも大変な4×4のステージもあります。クリアーまでのスピードを競い合いましょう。【 <a href="https://itunes.apple.com/jp/app/id936247301?mt=8" target="_blank">iOS</a> 】',
        "assets/images/icon_touchthememory.png",
        "https://itunes.apple.com/jp/app/id936247301?mt=8",
        "https://itunes.apple.com/jp/app/id936247301?mt=8"
      )
    );

    this.myApps2.push(
      new MyApp(
        "ブラタン（トムソーヤ合同会社）",
        'さまざまな施設・観光地について見どころがまとまっており、実際に遊んで体験できるようになっている「ブラブラ探検」サポートアプリです！観光地からの反応も非常に良好で、今後も適宜アップデートが想定されています。【 <a href="https://apps.apple.com/jp/app/id1549670119?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=jp.co.tom_sawyer.buratan" target="_blank">Android</a> / <a href="https://tom-sawyer.co.jp/buratan/" target="_blank">公式サイト</a> 】',
        "assets/images/icon_buratan.png",
        "https://itunes.apple.com/jp/app/id1549670119?mt=8",
        "https://play.google.com/store/apps/details?id=jp.co.tom_sawyer.buratan"
      )
    );

    this.myApps2.push(
      new MyApp(
        "よこすか中央ガイドマップ （ヨコスカダウンタウンクラブ）",
        '横須賀中央地域で使える、ガイドマップアプリです。横須賀中央地域を楽しむための、イベント情報・セール情報・クーポン情報を発信します！多くの店舗に登録いただいており、商店街の発展に貢献しています。【 <a href="https://apps.apple.com/jp/app/id1551986088?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=club.yokosukadowntown.guide" target="_blank">Android</a> 】',
        "assets/images/icon_yokosuka.png",
        "https://itunes.apple.com/jp/app/id1551986088?mt=8",
        "https://play.google.com/store/apps/details?id=club.yokosukadowntown.guide"
      )
    );

    this.myApps2.push(
      new MyApp(
        "Korette（CultiVison）",
        '旅の「いってみたい！」がみつかる。クイズで気軽に楽しく街の魅力が見つかる・発信できるWebサービス「Korette」のアプリ版。弊社代表がWebサービスを気に入り、アプリ化を全面サポートいたしました。【 <a href="https://itunes.apple.com/jp/app/id1446440835?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=fun.korette.koretteapp" target="_blank">Android</a> / <a href="https://korette.fun/" target="_blank">公式サイト</a> 】',
        "assets/images/icon_korette.png",
        "https://itunes.apple.com/jp/app/id1446440835?mt=8",
        "https://play.google.com/store/apps/details?id=fun.korette.koretteapp"
      )
    );

    this.myApps2.push(
      new MyApp(
        "キラ☆キラ（株式会社キッチンガイズファクトリー）",
        '恋して旅してロックすればイイじゃん。PC向けの大人気ビジュアルノベルゲームのアプリ版。<a href="https://camp-fire.jp/projects/view/25767" target="_blank">クラウドファンディング</a>でご支援いただいた資金をベースに、弊社で移植開発と申請に関するサポートを行いました。【 <a href="https://itunes.apple.com/jp/app/id1300274264?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=jp.co.k_g_f.kirakira" target="_blank">Android</a> / <a href="http://over-drive.jp/app/" target="_blank">公式サイト</a> 】',
        "assets/images/icon_kirakira.png",
        "https://itunes.apple.com/jp/app/id1300274264?mt=8",
        "https://play.google.com/store/apps/details?id=jp.co.k_g_f.kirakira"
      )
    );

    this.myApps2.push(
      new MyApp(
        "DEARDROPS（株式会社キッチンガイズファクトリー）",
        '音楽はいつでも君を待っている。PC向けの大人気ビジュアルノベルゲームのアプリ版。<a href="https://camp-fire.jp/projects/view/25767" target="_blank">クラウドファンディング</a>でご支援いただいた資金をベースに、弊社で移植開発と申請に関するサポートを行いました。【 <a href="https://itunes.apple.com/jp/app/id1300279841?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=jp.co.k_g_f.deardrops" target="_blank">Android</a> / <a href="http://over-drive.jp/app/" target="_blank">公式サイト</a> 】',
        "assets/images/icon_deardrops.png",
        "https://itunes.apple.com/jp/app/id1300279841?mt=8",
        "https://play.google.com/store/apps/details?id=jp.co.k_g_f.deardrops"
      )
    );

    this.myApps2.push(
      new MyApp(
        "おやこであそぼ！じゃじゃじゃじゃん（フォーリー株式会社）",
        '動画再生回数2億回以上、シリーズ累計200万DLを突破した、童謡や童話などの動画が親子で楽しめる知育アプリ。アプリとサーバーの大部分の開発を弊社が担当し、動画の暗号化や定期購読機能も搭載されています。【 <a href="https://itunes.apple.com/jp/app/id638751954?mt=8" target="_blank">iOS</a> / <a href="https://play.google.com/store/apps/details?id=jp.co.forii.jajajajan" target="_blank">Android</a> / <a href="http://www.forii.co.jp/" target="_blank">会社HP</a> 】',
        "assets/images/icon_jajajajan.png",
        "https://itunes.apple.com/jp/app/id638751954?mt=8",
        "https://play.google.com/store/apps/details?id=jp.co.forii.jajajajan"
      )
    );

    this.myApps2.push(
      new MyApp(
        "パンダのたぷたぷ - 重力ビリヤード（株式会社アイモバイル）",
        'パンダのたぷたぷ公式アプリ。「S,」のソースコードを流用する形で開発し、見た目もパンダのたぷたぷ用にリデザイン。ボールのサイズにバリエーションを持たせるなどの新しい要素も盛り込んであります。【 <a href="https://itunes.apple.com/jp/app/id1021929851?mt=8" target="_blank">iOS</a> / <a href="http://www.taputapu.jp/" target="_blank">公式サイト</a> 】',
        "assets/images/icon_taptap.png",
        "https://itunes.apple.com/jp/app/id1021929851?mt=8",
        "https://itunes.apple.com/jp/app/id1021929851?mt=8"
      )
    );
  }

  onClickMyApp(myApp: MyApp, i: number) {
    var galleryId = "#modalGallery";

    this.createGallery(galleryId, i, this.myApps);
    this.createPagination(galleryId, i, this.myApps);

    $(galleryId).on("hidden.bs.modal", () => {
      this.destroyGallry(galleryId);
      this.destroyPagination(galleryId);
    });

    $(galleryId + " .carousel").on("slid.bs.carousel", () => {
      var currentSlide = $(galleryId + " .carousel .item.active");
      var currentSlideIndex = currentSlide.index(
        galleryId + " .carousel .item"
      );

      this.setTitle(galleryId, currentSlideIndex, this.myApps);
      this.destroyPagination(galleryId);
      this.createPagination(galleryId, currentSlideIndex, this.myApps);
      // this.setPagination(++currentSlideIndex, true);
    });
  }

  onClickMyApp2(myApp: MyApp, i: number) {
    var galleryId = "#modalGallery";

    this.createGallery(galleryId, i, this.myApps2);
    this.createPagination(galleryId, i, this.myApps2);

    $(galleryId).on("hidden.bs.modal", () => {
      this.destroyGallry(galleryId);
      this.destroyPagination(galleryId);
    });

    $(galleryId + " .carousel").on("slid.bs.carousel", () => {
      var currentSlide = $(galleryId + " .carousel .item.active");
      var currentSlideIndex = currentSlide.index(
        galleryId + " .carousel .item"
      );

      this.setTitle(galleryId, currentSlideIndex, this.myApps2);
      this.destroyPagination(galleryId);
      this.createPagination(galleryId, currentSlideIndex, this.myApps2);
      // this.setPagination(++currentSlideIndex, true);
    });
  }

  onClickMyApp3(myApp: MyApp, i: number) {
    var galleryId = "#modalGallery";

    this.createGallery(galleryId, i, this.myApps3);
    this.createPagination(galleryId, i, this.myApps3);

    $(galleryId).on("hidden.bs.modal", () => {
      this.destroyGallry(galleryId);
      this.destroyPagination(galleryId);
    });

    $(galleryId + " .carousel").on("slid.bs.carousel", () => {
      var currentSlide = $(galleryId + " .carousel .item.active");
      var currentSlideIndex = currentSlide.index(
        galleryId + " .carousel .item"
      );

      this.setTitle(galleryId, currentSlideIndex, this.myApps3);
      this.destroyPagination(galleryId);
      this.createPagination(galleryId, currentSlideIndex, this.myApps3);
      // this.setPagination(++currentSlideIndex, true);
    });
  }

  createGallery(galleryId, currentSlideIndex, apps) {
    var galleryBox = $(galleryId + " .carousel-inner");

    for (var i = 0; i < apps.length; i++) {
      var myApp = apps[i];
      var url =
        this.device.os == "ios" || this.device.os == "mac"
          ? myApp.ios_url
          : myApp.android_url;
      var galleryItem = $(
        '<div class="item"><a href="' +
          url +
          '" target="_blank"><img style="margin:auto" src=' +
          myApp.img +
          '></a><p style="margin-top:15px">' +
          myApp.desc +
          "</p></div>"
      );

      galleryItem.appendTo(galleryBox);
    }

    galleryBox.children(".item").eq(currentSlideIndex).addClass("active");
    this.setTitle(galleryId, currentSlideIndex, apps);
  }

  createPagination(galleryId, currentSlideIndex, apps) {
    var pagination = $(galleryId + " .pagination");
    var carouselId = $(galleryId).find(".carousel").attr("id");
    var prevLink = $(
      '<li><a href="#' + carouselId + '" data-slide="prev">«</a></li>'
    );
    var nextLink = $(
      '<li><a href="#' + carouselId + '" data-slide="next">»</a></li>'
    );

    prevLink.appendTo(pagination);
    nextLink.appendTo(pagination);

    var startIndex = 0;
    if (currentSlideIndex >= 2) {
      startIndex = currentSlideIndex - 2;
      startIndex -=
        2 - (apps.length - 1 - currentSlideIndex) < 0
          ? 0
          : 2 - (apps.length - 1 - currentSlideIndex);
    }
    var maxIndex = apps.length - 1;
    if (apps.length - 1 - currentSlideIndex >= 2) {
      maxIndex = currentSlideIndex + 2;
      maxIndex += currentSlideIndex < 2 ? 2 - currentSlideIndex : 0;
    }
    if (4 >= apps.length) {
      startIndex = 0;
      for (var i = startIndex; i <= apps.length - 1; i++) {
        var linkIndex = i;
        var paginationLink = $(
          '<li><a data-target="#carouselGallery" data-slide-to="' +
            linkIndex +
            '">' +
            (linkIndex + 1) +
            "</a></li>"
        );

        paginationLink.insertBefore(".pagination li:last-child");
      }
      this.setPagination(++currentSlideIndex, startIndex);
    } else {
      for (var i = startIndex; i <= maxIndex; i++) {
        var linkIndex = i;
        if (linkIndex == -1) continue;
        var paginationLink = $(
          '<li><a data-target="#carouselGallery" data-slide-to="' +
            linkIndex +
            '">' +
            (linkIndex + 1) +
            "</a></li>"
        );

        paginationLink.insertBefore(".pagination li:last-child");
      }
      this.setPagination(++currentSlideIndex, startIndex);
    }
  }

  setPagination(currentSlideIndex, startIndex) {
    $(".pagination li")
      .eq(currentSlideIndex - startIndex)
      .addClass("active");
  }

  setTitle(galleryId, currentSlideIndex, apps) {
    var imgAlt = $(galleryId + " .item")
      .eq(currentSlideIndex)
      .find("img")
      .attr("alt");
    var myApp = apps[currentSlideIndex];

    $(".modal-title").text(myApp.title);
  }

  destroyGallry(galleryId) {
    $(galleryId + " .carousel-inner").html("");
  }

  destroyPagination(galleryId) {
    $(galleryId + " .pagination").html("");
  }
}

$(function () {
  $('a[href^="#pos-"]').click(function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

class MyApp {
  title;
  desc;
  img;
  ios_url;
  android_url;

  constructor(title, desc, img, ios_url, android_url) {
    this.title = title;
    this.desc = desc;
    this.img = img;
    this.ios_url = ios_url;
    this.android_url = android_url;
  }
}
